*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling on the main container */
}

.App-header {
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.App-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.tally-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20vh; /* Match the footer height */
}

.tally-group {
  position: relative;
  display: flex;
  align-items: center;
  width: auto; /* Changed to auto */
  margin: 13px 7px; /* Updated margin */
}

.tally-mark {
  width: 10px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  margin: 2px;
}

.slash {
  position: absolute;
  left: -5px;
  top: 14px;
  display: inline-block;
  font-size: 4rem;
  margin: 2px;
  width: 62px;
  height: 35px;
}

.App-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20vh; /* Increased to accommodate credit line */
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1000;
}

.total-count {
  font-size: 1.5rem;
  color: white;
}

.button-container {
  display: flex;
  gap: 10px;
}

.App-footer button,
.introduction button {
  font-size: 1rem;
  padding: 10px 20px;
  color: #000; /* Dark text color for green buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: #4CAF50; /* Nice green color */
}

.App-footer button:not(.reset-button):hover:not(:disabled),
.introduction button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.App-footer button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.App-footer .reset-button {
  background-color: #f44336; /* Keep red color for reset button */
  color: #fff; /* White text for reset button */
}

.App-footer .reset-button:hover:not(:disabled) {
  background-color: #d32f2f; /* Darker red on hover */
}

.introduction button {
  align-self: center;
  font-size: 1.1rem;
  padding: 12px 24px;
  margin-top: 20px;
}

.ad-space {
  display: block;
  height: 100px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  width: 100%;
}

.ad-space p {
  color: #555; /* Darker color for 'advertisement' text */
  font-style: italic;
  text-align: center;
  line-height: 100px;
  margin: 0;
}

/* Media query for desktop */
@media screen and (min-width: 1024px) {
  .ad-space {
    width: 66%;
    margin-left: auto;
    margin-right: auto;
  }
}

.intro-footer {
  height: 12vh; /* Slightly increased for intro page */
  justify-content: flex-end; /* Align credit to bottom */
  padding-bottom: 10px; /* Add some padding at the bottom */
}

.credit {
  color: #61dafb;
  font-size: 0.8rem;
  margin-top: 10px;
}

.credit a {
  color: #61dafb;
  text-decoration: underline; /* Changed to underline */
}

.credit a:hover {
  text-decoration: none; /* Remove underline on hover for visual feedback */
}

.introduction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh 5vw;
  background-color: #f8f9fa;
  height: calc(100vh - 120px - 20vh); /* Subtracting header and footer heights */
  overflow: auto; /* Add scrolling if content exceeds height on very small screens */
}

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: flex-start;
  text-align: center; /* Center text */
  padding: 3vh 4vw;
  width: calc(100% - 40px); /* Subtract 20px from each side */
  max-width: 600px;
  margin: 20px auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.introduction h1 {
  margin-bottom: 2vh;
  color: #333;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 700;
}

.introduction p {
  color: #555;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  line-height: 1.6;
  margin-bottom: 2vh;
}

.introduction ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 2vh;
  width: 100%; /* Ensure full width for proper centering */
}

.introduction li {
  margin-bottom: 1.5vh;
  color: #444;
  font-size: clamp(0.8rem, 1.8vw, 1rem);
  line-height: 1.4;
}

/* Remove this rule as we no longer need custom bullets */
/* .introduction li::before {
  content: '•';
  color: #4CAF50;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -2px;
} */

.introduction button {
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  padding: 1.5vh 3vw;
  margin-top: 2vh;
}

.content-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}
